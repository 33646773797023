import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { fieldRequired } from '../helpers/validation';
import { getApiUrl } from '../helpers/lookup';
import { removeLoginDetails } from '../helpers/authentication';
import { FormGroup, FormText, Input, Label } from 'reactstrap';
import ThrobbleHelper from '../css/ThrobbleHelper';

export default function ResetPassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showThrobble, setShowThrobble] = useState(false);
    const [throbbleMessage, setThrobbleMessage] = useState("");

    const navigate = useNavigate();

    const userId = window.location.pathname.split("/")[2];

    const setThrobble = (displayThrobble, throbbleMsg) => {
        setThrobbleMessage(throbbleMsg);
        setShowThrobble(displayThrobble);
    };

    const handleError = (error) => {
        console.error("Error :: ", error);
        setThrobble(false);
        swal("Oops!", error.message, "error");
    };

    const confirmPasswordMatch = (
        value,
        errorContainer,
        errorMessage
    ) => {
        if (fieldRequired(value, errorContainer, "")) {
            if (value === password) {
                return true;
            }
        }

        fieldRequired(value, errorContainer, errorMessage);
        return false;
    };

    const validatePassword = () => {
        const isPasswordValid = fieldRequired(
            password,
            "errPasswordReset",
            "required"
        );
        const isConfirmPasswordValid = confirmPasswordMatch(
            confirmPassword,
            "errConfirmPasswordReset",
            "Must match given password"
        );

        return isPasswordValid && isConfirmPasswordValid;
    };

    const resetPasswordRequest = async (userResetData) => {
        const response = await fetch(`${getApiUrl()}/login/UpdatePassword`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userResetData),
        });

        if (!response.ok) {
            console.log(response.status + ": " + response.statusText);
            throw new Error(
                "An error occurred when resetting your password. Please try again."
            );
        }
    };

    const passwordReset = async (e) => {
        e.stopPropagation();

        try {
            setThrobble(true, "Resetting Password...");
            if (!validatePassword()) {
                throw new Error(
                    "Password is invalid, please fill in the password fields correctly."
                );
            }

            const userResetData = {
                UserId: userId,
                Password: password,
            };

            await resetPasswordRequest(userResetData);

            setThrobble(false);
            swal(
                "Success",
                "Your password has been successfully reset. You will be redirected to the login page.",
                "success"
            ).then(() => {
                removeLoginDetails();
                navigate("/login");
            });
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <div className="card shadow-lg bg-body rounded">
            <div className="card-body">
                <h3 className="text-uppercase mb-4">Reset Password</h3>
                <FormGroup>
                    <Label>
                        Password
                        <span className="required-icon">*</span>
                        <span id="errPasswordReset" className="ErrorText" />
                    </Label>
                    <Input
                        type="password"
                        onChange={(e) => {
                            setPassword(e.target.value);
                            fieldRequired(e.target.value, "errPasswordReset", "required");
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Confirm Password
                        <span className="required-icon">*</span>
                        <span id="errConfirmPasswordReset" className="ErrorText" />
                    </Label>
                    <Input
                        type="password"
                        onChange={(e) => {
                            console.log(e);
                            setConfirmPassword(e.target.value);
                            fieldRequired(
                                e.target.value,
                                "errConfirmPasswordReset",
                                "required"
                            );
                        }}
                    />
                    <FormText color="muted">
                        Password entered here should match the one in the above password
                        field.
                    </FormText>
                </FormGroup>

                <div className="d-flex justify-content-between mb-3 align-items-center">
                    <button
                        type="button"
                        className="btn btn-purple"
                        onClick={passwordReset}
                    >
                        Reset
                    </button>
                </div>
                <hr />
                <div className="small">
                    <a
                        href="https://estafrica.co.za/privacy-policy/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Privacy Policy
                    </a>
                </div>
            </div>

            <ThrobbleHelper
                showThrobble={showThrobble}
                throbbleMessage={throbbleMessage}
            />
        </div>
    );
}
