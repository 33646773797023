import React, { Component } from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

export default class LoggedInLayout extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="logged-container-content">
                    <Outlet />
                </div>
                <Footer />
            </>
        );
    }
}
