/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { getIsLoggedIn } from "../../helpers/authentication";
import { NavLink  } from "react-router-dom";

export default class Footer extends Component {
      
      render() {
    return (
      <footer className="fixed-bottom py-1" style={{bottom: "0", left: "50%", transform: "translate(-50%, 0)"}} hidden={!getIsLoggedIn()}>
        <div className="row" style={{maxWidth: "600px", margin: "0 auto"}}>
          <div className="col-4 text-center">
            <NavLink to={"/"} ><i className="fas fa-home fa-2x"></i></NavLink>
          </div>
          <div className="col-4 text-center">
          <NavLink to={"/qrscanner"} ><i className="fas fa-barcode-read fa-2x"></i></NavLink>
          </div>
          <div className="col-4 text-center">
          <NavLink to={"/profile"} ><i className="fas fa-user fa-2x"></i></NavLink>
          </div>
        </div>
      </footer>
    );
  }
}
