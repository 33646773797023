export const getAuthToken = () => {
  if (window.localStorage.getItem("AuthToken") != null) {
    var data = JSON.parse(window.localStorage.getItem("AuthToken"));
    return data.authToken;
  }
  else
    return null;
};

export const setAuthToken = (loginCookie) => {
  const data = { authToken: loginCookie }
  try {
    window.localStorage.setItem("AuthToken", JSON.stringify(data));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

//   export const removeAuthToken = () => {
//     if (window.localStorage.getItem("AuthToken") != null) {
//       window.localStorage.removeItem("AuthToken");
//       //todo: check expiry
//       return true;
//     }
//     else
//       return false;

//   };

export const removeLoginDetails = () => {
  window.localStorage.removeItem("AuthToken");
  window.localStorage.removeItem("Username");
  window.sessionStorage.removeItem("IsLoggedIn");
}

export const getIsLoggedIn = () => {
  if (window.localStorage.getItem("AuthToken") !== null && window.localStorage.getItem("AuthToken") !== undefined)
    return true;
  else
    return false;
}

export const setIsLoggedIn = (value) => {
  try {
    window.sessionStorage.setItem("IsLoggedIn", value);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const getFullname = () => {
  if (window.localStorage.getItem("Username") !== null || (window.localStorage.getItem("Username") !== undefined))
    return window.localStorage.getItem("Username");
  else
    return null;
};

export const setFullname = (props) => {
  try {
    window.localStorage.setItem("Username", props);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }

};

export const getSupplierName = () => {
  if (window.localStorage.getItem("SupplierName") !== null || (window.localStorage.getItem("SupplierName") !== undefined))
    return window.localStorage.getItem("SupplierName");
  else
    return "N/A";
};

export const setSupplierName = (props) => {
  try {
    window.localStorage.setItem("SupplierName", props);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }

};

