import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getAuthToken, getFullname, getSupplierName, removeLoginDetails } from '../helpers/authentication';
import { getApiUrl } from '../helpers/lookup';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import moment from 'moment/moment';
import ThrobbleHelper from '../css/ThrobbleHelper';
import swal from 'sweetalert';

export default function Visitors() {

    const [SupplierName, setSupplierName] = useState("");
    const [ScannedVisitors, setScannedVisitors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [SelectedVisitor, setSelectedVisitor] = useState(null);
    const [ShowVisitor, setShowVisitor] = useState(false);
    const [showThrobble, setShowThrobble] = useState(false);
    const [throbbleMessage, setThrobbleMessage] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        if (navigator.onLine) {
            loadData();
        } else
            navigate("/");
    }, [])

    const loadData = async () => {
        const bearer = "Bearer " + getAuthToken();

        setShowThrobble(true);
        setThrobbleMessage("Loading scanned visitors");
        try {
            const response = await fetch(getApiUrl() + "/visitslog/ListAllForSupplier",
                {
                    method: "GET",
                    headers: {
                        Authorization: bearer,
                        "Content-Type": "application/json",
                    },
                });

            if (response.ok) {

                const ScannedVisitors = await response.json();
                console.log(ScannedVisitors);
                setScannedVisitors(ScannedVisitors);
                setLoading(false);
                setShowThrobble(false);
            } else {
                if (response.status === 401) {
                    //todo: uncomment this...
                     removeLoginDetails();
                     window.location.href = "/login";
                } else
                    setLoading(false);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const toggle = () => setShowVisitor(!ShowVisitor);

    const showVisitorDetails = (item) => {
        setSelectedVisitor(item);
        setShowVisitor(true);
    };

    const renderScannedVisitors = () => {
        return (
            ScannedVisitors.length > 0 ?
                ScannedVisitors.map(item =>
                    <Card className='text-center mb-2' key={item.id} onClick={() => showVisitorDetails(item)}>
                        <CardBody>
                            <h3 className='fw-bold'>{item.visitorName}</h3>
                            <small> {moment(item.dateVisited).format("ddd, DD MMMM yyyy HH:mm a")}</small>
                        </CardBody>
                    </Card>
                )
                :
                <p className='text-center text-danger mt-5'>
                    <i>
                    No visitors have been scanned for {getSupplierName()} yet.  <br />
                    Be the first by clicking the scan button below.
                    </i>
                    </p>
        )
    }

    const content = loading ? <></> : renderScannedVisitors();

    const exportVisitorsReport = async () => {
        const bearer = "Bearer " + getAuthToken();

        try {
            setShowThrobble(true);
            setThrobbleMessage("Exporting visitor report");

            const response = await fetch(getApiUrl() + "/visitslog/ExportVisitorReport", {
                method: "GET",
                headers:
                {
                    Authorization: bearer,
                    "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            });

            if (response.ok) {
                const data = await response.blob();
                const fileUrl = URL.createObjectURL(data);
                const LinkBtn = document.createElement("a");
                LinkBtn.download = getSupplierName().replace(" ", "-") + "_Visitor_Report.xlsx";
                LinkBtn.href = fileUrl;
                LinkBtn.click();
                swal("Visitor Report", "Visitor report was succcessfully exported!", "success")
                setShowThrobble(false);
            }
        } catch (e) {
            swal("Visitor Report", "There was an error exporting visitor report. Please try again!", "error")
        }
    }
    return (
        <div>
            <h4>Welcome {getFullname()}</h4>
            <b>Supplier Name: </b>{getSupplierName()}
            <div className="mt-2" hidden={ScannedVisitors.length === 0}>
                <button type='button' className='btn-purple btn-sm' onClick={exportVisitorsReport}><i className='fas fa-download me-2'></i>Export Visitors Report</button>
            </div>
            <div className="py-3 mt-2">
                {content}
            </div>

            <Modal isOpen={ShowVisitor} toggle={toggle} centered>
                <ModalHeader className="justify-content-center">
                    Visitor Details
                </ModalHeader>
                <ModalBody className="text-center">
                    {SelectedVisitor &&
                        <>
                            <b>Fullname </b><br />{SelectedVisitor.visitorName}<br />
                            <b>Email Address</b><br />{SelectedVisitor.visitorEmail ? SelectedVisitor.visitorEmail : "N/A"}<br />
                            <b>Contact No. </b><br />{SelectedVisitor.visitorContact ? SelectedVisitor.visitorContact : "N/A"}<br />
                            <b>Company </b><br />{SelectedVisitor.visitorCompany ? SelectedVisitor.visitorCompany : "N/A"}
                        </>}
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-purple" size='sm' onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>

            <ThrobbleHelper showThrobble={showThrobble} throbbleMessage={throbbleMessage} />
        </div>
    )
}
