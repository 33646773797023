/* eslint-disable jsx-a11y/alt-text */


import { useState, ChangeEvent, FormEvent } from "react";
import { FormGroup, Label, Input, FormText } from "reactstrap";
import { fieldRequired } from "../helpers/validation";
import swal from "sweetalert";
import ThrobbleHelper from "../helpers/ThrobbleHelper";
import { setIsLoggedIn, setAuthToken, setFullname, } from "../helpers/authentication";
import { GetProvinceList, getApiUrl, getAppVersion } from "../helpers/lookup";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

function UserRegistration() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false);
  const [company, setCompany] = useState("");
  const [province, setProvince] = useState(0);
  const [showThrobble, setShowThrobble] = useState(false);
  const [throbbleMessage, setThrobbleMessage] = useState("");

  const handleError = (error) => {
    console.error("Error :: ", error);
    swal("Error", error.message, "error");
  };

  const validateRegistrationDetails = () => {
    const isFirstnameValid = fieldRequired(firstName, "errFirstName", "required");
    const isLastnameValid = fieldRequired(lastName, "errLastName", "required");
    const isEmailValid = fieldRequired(email, "errEmail", "required");
    const isPasswordValid = fieldRequired(password, "errPassword", "required");
    const isMobileValid = fieldRequired(mobile, "errMobile", "required");
    const isCompanyValid = fieldRequired(company, "errCompany", "required");
    const isProvinceValid = fieldRequired(province, "errProvince", "required");

    const isValid =
      isFirstnameValid &&
      isLastnameValid &&
      isEmailValid &&
      isPasswordValid &&
      isMobileValid &&
      isCompanyValid &&
      isProvinceValid;

    return isValid && isPasswordConfirmed;
  };

  const setThrobble = (displayThrobble, throbbleMsg) => {
    setThrobbleMessage(throbbleMsg);
    setShowThrobble(displayThrobble);
  };


  const registerUserRequest = async (userRegistrationData: {
    Id: number;
    FirstName: string;
    LastName: string;
    Mobile: string;
    EmailAddress: string;
    Password: string;
    CompanyName: string;
  }) => {
    const response = await fetch(`${getApiUrl()}/registration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userRegistrationData),
    });

    if (!response.ok) {
      console.log(response.status + ": " + response.statusText);
      setThrobble(false);

      if (response.status === 409) {
        throw new Error("A user with this e-mail already exists");
      }
      if (response.status === 406) {
        throw new Error(
          "An error occurred during registration. Please try again or contact admin for assistance"
        );
      }
    }

    return await response.json();
  };

  const RegisterUser = async (e: FormEvent) => {
    e.stopPropagation();

    try {
      if (validateRegistrationDetails()) {
        setThrobble(true, "Registering...");

        const userRegistrationData = {
          Id: 0,
          FirstName: firstName,
          LastName: lastName,
          Mobile: mobile,
          EmailAddress: email,
          Password: password,
          CompanyName: company,
          Province: province,
        };

        const data = await registerUserRequest(userRegistrationData);

        setThrobble(false);

        swal({
          title: "Success",
          text: "You have been successfully registered",
          icon: "success",
        }).then(() => {
          setAuthToken(data.item1);
          setFullname(data.item2);
          setIsLoggedIn(true);

          window.location.href = "/";
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  const ProvinceList = GetProvinceList();
  return (
    <>
      <div className="card shadow-lg bg-body rounded mb-3">
        <div className="card-body">
          <h3 className="text-uppercase mb-4">Register</h3>

          <FormGroup>
            <Label>
              First Name
              <span className="required-icon">*</span>
              <span id="errFirstName" className="ErrorText" />
            </Label>
            <Input
              type="text"
              bsSize="sm"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setFirstName(e.target.value);
                fieldRequired(e.target.value, "errFirstName", "required");
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Last Name
              <span className="required-icon">*</span>
              <span id="errLastName" className="ErrorText" />
            </Label>
            <Input
              type="text"
              bsSize="sm"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setLastName(e.target.value);
                fieldRequired(e.target.value, "errLastName", "required");
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Email
              <span className="required-icon">*</span>
              <span id="errEmail" className="ErrorText" />
            </Label>
            <Input
              type="email"
              bsSize="sm"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value);
                fieldRequired(e.target.value, "errEmail", "required");
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Password
              <span className="required-icon">*</span>
              <span id="errPassword" className="ErrorText" />
            </Label>
            <Input
              type="password"
              bsSize="sm"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPassword(e.target.value);
                fieldRequired(e.target.value, "errPassword", "required");
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Confirm Password
              <span className="required-icon">*</span>
              <span id="errConfirmPassword" className="ErrorText" />
            </Label>
            <Input
              type="password"
              bsSize="sm"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setIsPasswordConfirmed(e.target.value === password);
                fieldRequired(
                  e.target.value === password,
                  "errConfirmPassword",
                  "Password doesn't match"
                );
              }}
            />
            <FormText color="muted">
              Password entered here should match the one in the above password
              field.
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label>
              Mobile
              <span className="required-icon">*</span>
              <span id="errMobile" className="ErrorText" />
            </Label>
            <Input
              type="text"
              bsSize="sm"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setMobile(e.target.value);
                fieldRequired(e.target.value, "errMobile", "required");
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Company Name
              <span className="required-icon">*</span>
              <span id="errCompany" className="ErrorText" />
            </Label>
            <Input
              type="text"
              bsSize="sm"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setCompany(e.target.value);
                fieldRequired(e.target.value, "errCompany", "required");
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Province
              <span className="required-icon">*</span>
              <span id="errProvince" className="ErrorText" />
            </Label>
            <DropDownListComponent dataSource={ProvinceList} fields={{ text: "text", value: "value" }} value={province} placeholder="- Select Province -" select={(e) => { setProvince(e.itemData.value); fieldRequired(e.itemData.value, "errProvince", "required"); }} />
          </FormGroup>

          <div className="d-flex justify-content-between mb-3">
            <small className="align-self-end">
              Already have account? <a href="/login">Login</a>
            </small>
            <button type="button" className="btn btn-purple" onClick={RegisterUser}            >              Register            </button>
          </div>
          <hr />
          <div className="small">
            <a href="https://estafrica.co.za/privacy-policy/" target="_blank" rel="noreferrer noopener"            >              Privacy Policy            </a>
          </div>
        </div>
      </div>
      <div className="text-purple text-center py-3">Version: {getAppVersion()}</div>
      <ThrobbleHelper showThrobble={showThrobble} throbbleMessage={throbbleMessage} />
    </>
  );
}

export default UserRegistration;
