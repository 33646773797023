import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { getIsLoggedIn } from './helpers/authentication';
import LoggedInLayout from './Components/Layouts/LoggedInLayout';
import NotFoundPage from './Components/404Page';
import Visitors from './Components/VisitReports';
import QRScanner from './Components/QRComponents/QR';
import Profile from './Components/Profile';
import ScanResult from './Components/QRComponents/ScanResult';
import ForgotPassword from './Components/ForgotPassword';
import NotLoggedLayout from './Components/Layouts/NotLoggedInLayout';
import UserRegistration from './Components/UserRegistration';
import Login from './Components/Login';
import Home from './Components/home';
import "./css/est-africa.css";
import ResetPassword from './Components/ResetPassword';


function App() {
  const contentRef = useRef(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {

    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    }
  }, []);

  const handleTouch = () => { };

  return (
    <>
      {getIsLoggedIn() ? (
        <>
          <div ref={contentRef} onTouchStart={handleTouch}>
            <Routes>
              <Route path="/" element={isOnline ? <LoggedInLayout /> : <NotFoundPage />}              >
                <Route index element={isOnline ? <Visitors /> : <NotFoundPage />} />
                <Route path="/qrscanner" element={isOnline ? <QRScanner /> : <NotFoundPage />} />
                <Route path="/profile" element={isOnline ? <Profile /> : <NotFoundPage />} />
                <Route path="/scan-result" element={isOnline ? <ScanResult /> : <NotFoundPage />} />
                <Route path="/passwordreset/:userid" element={isOnline ? <ResetPassword /> : <NotFoundPage />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Route>
            </Routes>
          </div>
        </>
      ) : (
        <Routes>
          <Route path="/" element={isOnline ? <NotLoggedLayout /> : <NotFoundPage />}>
            <Route index element={isOnline ? <Home /> : <NotFoundPage />} />
            <Route path="/login" element={isOnline ? <Login /> : <NotFoundPage />} />
            <Route path="/passwordreset/:userid" element={isOnline ? <ResetPassword /> : <NotFoundPage />} />
            <Route path="/register" element={isOnline ? <UserRegistration /> : <NotFoundPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      )}

    </>
  );
}

export default App;
