import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Input } from 'reactstrap';
import swal from 'sweetalert';
import ThrobbleHelper from '../css/ThrobbleHelper';
import { fieldRequired } from '../helpers/validation';
import { getAuthToken, removeLoginDetails, setFullname } from '../helpers/authentication';
import { GetProvinceList, getApiUrl } from '../helpers/lookup';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useNavigate } from 'react-router-dom';

export default function Profile() {
    const [Id, SetId] = useState(0);
    const [FirstName, SetFirstname] = useState("");
    const [LastName, SetLastname] = useState("");
    const [EmailAddress, SetEmailAddress] = useState("");
    const [Mobile, SetMobile] = useState("");
    const [Province, SetProvince] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showThrobble, setShowThrobble] = useState(false);
    const [throbbleMessage, setThrobbleMessage] = useState("");
    const navigate = useNavigate();
    const bearer = `Bearer ${getAuthToken()}`;

    useEffect(() => {
        if (navigator.onLine) {
            loadData();
        } else {
            navigate("/");
        }
    }, []);

    const loadData = async () => {
        try {
            const response = await fetch(`${getApiUrl()}/userdata/LoadUserData`, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                const data = await response.json();

                SetId(data.id);
                SetFirstname(data.firstName);
                SetLastname(data.lastName);
                SetEmailAddress(data.emailAddress);
                SetMobile(data.mobile);
                SetProvince(data.province);
                setLoading(false);
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/login";
                } else {
                    setLoading(false);
                }
            }
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const renderUserForm = () => {
        const ProvinceList = GetProvinceList();
        return (
            <>
                <div className="mb-3">
                    <label>
                        First name
                        <span className="required-icon">*</span>{" "}
                        <span id="errFirstName" className="ErrorText"></span>
                    </label>
                    <Input type="text" bsSize="sm" defaultValue={FirstName} onChange={(e) => SetFirstname(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label>
                        Last name
                        <span className="required-icon">*</span>{" "}
                        <span id="errLastName" className="ErrorText"></span>
                    </label>
                    <Input type="text" bsSize="sm" defaultValue={LastName} onChange={(e) => SetLastname(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label>
                        Email Address
                        <span className="required-icon">*</span>{" "}
                        <span id="errEmail" className="ErrorText"></span>
                    </label>
                    <Input type="text" bsSize="sm" defaultValue={EmailAddress} onChange={(e) => SetEmailAddress(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label>
                        Mobile
                        <span className="required-icon">*</span>{" "}
                        <span id="errMobile" className="ErrorText"></span>
                    </label>
                    <Input type="text" bsSize="sm" defaultValue={Mobile} onChange={(e) => SetMobile(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label>
                        Province
                        <span className="required-icon">*</span>
                        <span id="errProvince" className="ErrorText" />
                    </label>
                    <DropDownListComponent dataSource={ProvinceList} fields={{ text: "text", value: "value" }} value={Province} placeholder="- Select Province -" select={(e) => { SetProvince(e.itemData.value); fieldRequired(e.itemData.value, "errProvince", "required"); }} />
                </div>

                <div className='row'>
                    <div className='col-6'>
                        <Button color="dark" size="sm" onClick={Logout}><i className="fas fa-sign-out-alt me-2"></i>Logout</Button>
                    </div>
                    <div className="col-6 text-end">
                        <Button color="success" size="sm" onClick={SaveProfile}><i className="far fa-check-circle me-2"></i>Update Profile</Button>
                    </div>
                </div>
            </>
        );
    };


    const Logout = async (evt) => {

        evt.preventDefault();
        // removeLoginDetails();
        // window.location.href = "/login";
        const confirmLogout = window.confirm("Are you sure you want to logout?");
        if (confirmLogout) {
            removeLoginDetails();
            window.location.href = "/login";
        }
    };


    const setThrobble = (displayThrobble, throbbleMsg = "") => {
        setThrobbleMessage(throbbleMsg);
        setShowThrobble(displayThrobble);
    };

    const handleError = (error) => {
        console.error("Error :: ", error);
        swal("Error", error.message, "error");
    };

    const saveProfileRequest = async (profileData) => {
        const response = await fetch(`${getApiUrl()}/userdata/updateprofile`, {
            method: "PUT",
            headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(profileData),
        });

        if (!response.ok) {
            console.log(response.status + ": " + response.statusText);
            setThrobble(false);

            if (response.status === 401) {
                removeLoginDetails();
                window.location.href = "/login";
            }

            if (response.status === 409) {
                throw new Error("An account with this e-mail already exists");
            }
            if (response.status === 406) {
                throw new Error("An error occurred during saving profile.");
            }
        }
    };

    const validateProfileData = () => {
        const isFirstnameValid = fieldRequired(
            FirstName,
            "errFirstName",
            "required"
        );
        const isLastnameValid = fieldRequired(LastName, "errLastName", "required");
        const isEmailValid = fieldRequired(EmailAddress, "errEmail", "required");
        const isMobileValid = fieldRequired(Mobile, "errMobile", "required");
        const isProvinceValid = fieldRequired(Province, "errProvince", "required");

        return (
            isFirstnameValid &&
            isLastnameValid &&
            isEmailValid &&
            isMobileValid &&
            isProvinceValid
        );
    };

    const SaveProfile = async (evt) => {
        evt.preventDefault();

        try {
            if (validateProfileData()) {
                setThrobble(true, "Saving Profile...");
                const profileData = {
                    Id,
                    FirstName,
                    LastName,
                    EmailAddress,
                    Mobile,
                    Province,
                };

                await saveProfileRequest(profileData);
                setFullname(profileData.FirstName + " " + profileData.LastName);
                setThrobble(false);
                swal("Profile", "Profile was successfully updated!", "success").then(
                    () => {
                        loadData();
                    }
                );
            }
        } catch (e) {
            handleError(e);
        }
    };

    const content = loading ? (
        <ThrobbleHelper showThrobble={true} throbbleMessage={"Loading profile"} />
    ) : (
        renderUserForm()
    );

    return (
        <div>
            <Card className="shadow-lg bg-body rounded">
                <CardBody>
                    <h3 className="text-uppercase mb-4">Profile</h3>
                    <div className="mt-2">{content}</div>
                </CardBody>
            </Card>
            <ThrobbleHelper
                showThrobble={showThrobble}
                throbbleMessage={throbbleMessage}
            />
        </div>
    );
}
