import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFoundPage() {
  return (
    <div className="d-flex flex-column justify-content-between text-center" style={{height: "50vh"}}>
    <div className="container text-center mb-5">
        <Link to={"/"}>
          <img src="/assets/EST-Africa-Logo.png" className="img-fluid " alt="E.S.T Africa"/>
        </Link>
      </div>
      <div>
    <h1>Offline</h1>
    <p>You are currently offline. Please check your network connection or try again when you have a connection.</p>
    </div>
  </div>
  )
}
