import { Component } from 'react';
import { Spinner } from 'reactstrap';

export default class ThrobbleHelper extends Component {
    render() {
        const { showThrobble, throbbleMessage } = this.props;

        return (
            <div hidden={!showThrobble} className='throbble-wrapper'>
                <div className="throbble-inner">
                    <div className="spinner-block my-3">
                        <Spinner className='text-white'></Spinner>
                    </div>
                    <div className="message-block">
                        {throbbleMessage}
                    </div>
                </div>
            </div>
        );
    }
}
