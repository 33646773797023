import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { getApiUrl } from '../helpers/lookup';
import { fieldRequired } from '../helpers/validation';
import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ThrobbleHelper from '../css/ThrobbleHelper';

export default function ForgotPassword(props) {

    const [username, setUsername] = useState("");
    const [showThrobble, setShowThrobble] = useState(false);
    const [throbbleMessage, setThrobbleMessage] = useState("");

    const navigate = useNavigate();

    const setThrobble = (displayThrobble, throbbleMsg = "") => {
        setThrobbleMessage(throbbleMsg);
        setShowThrobble(displayThrobble);
    };

    const checkEmail = async (e) => {
        e.stopPropagation();

        try {
            const isUsernameValid = fieldRequired(
                username,
                "errForgotPassword",
                " required"
            );

            if (isUsernameValid) {
                setThrobble(true, "Checking Email address...");
                const response = await fetch(
                    `${getApiUrl()}/login/CheckEmailAddress?username=${username}`,
                    {
                        method: "GET",
                    }
                );
                console.log(response);
                if (response.ok) {
                    setThrobble(false);
                    //   navigate("/login", { state: { username } });
                    swal(
                        "Forgot Password",
                        "Your account was successfully retrieved. Please check your email inbox for reset password message.",
                        "success"
                    )
                    toggleModal();
                } else {
                    setThrobble(false);
                    switch (response.status) {
                        case 406:
                            swal(
                                "Account Suspended".toUpperCase(),
                                "The account associated with this email has been suspended. Please contact admin for further assistance.",
                                "error"
                            );
                            break;
                        case 404:
                            swal(
                                "Error".toUpperCase(),
                                "Email address is not available in the system.",
                                "error"
                            );
                            break;
                        default:
                            swal(
                                "Error".toUpperCase(),
                                "There was an error checking email address. Please try again",
                                "error"
                            );
                            break;
                    }
                }
            }
        } catch (error) {
            console.error("Error :: ", error);
            setThrobble(false);
            swal(
                "Error".toUpperCase(),
                "There was an error checking email address. Please try again",
                "error"
            );
        }
    };

    const toggleModal = () => {
        props.toggleShowPassword(!props.showModal);
    };

    return (
        <>
            <Modal isOpen={props.showModal} fade>
                <ModalHeader toggle={toggleModal}>Reset Password</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        {" "}
                        <Label>
                            Email Address <span className="required-icon">*</span>
                            <span id="errForgotPassword" className="ErrorText" />{" "}
                        </Label>{" "}
                        <Input
                            type="email"
                            bsSize="sm"
                            onChange={(e) => {
                                setUsername(e.target.value);
                                fieldRequired(e.target.value, "errForgotPassword", "required");
                            }}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        onClick={toggleModal}
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="btn btn-purple btn-sm"
                        onClick={checkEmail}
                    >
                        Submit
                    </button>
                </ModalFooter>
            </Modal>
            <ThrobbleHelper
                showThrobble={showThrobble}
                throbbleMessage={throbbleMessage}
            />
        </>
    )
}
