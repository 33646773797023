/* eslint-disable jsx-a11y/alt-text */
import { Component } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";

export default class NotLoggedLayout extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="container-content">
                    <Outlet />
                </div>
            </>
        );
    }
}
